<main>
	<div>
		<a href="https://instagram.com/durasjan"><img width="320" src="../logo.svg" alt="JD Logo" /></a>
	</div>
</main>

<style>
	:global(body) {
		overflow: hidden;
		width: 100vw;
		height: 100vh;
		margin: 0;

		background-color: #121212;
		background-image: url('../bg.jpg');
		background-position: center;
  		background-repeat: no-repeat;
  		background-size: cover;
	}

	div {
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;
		flex-wrap: wrap;
		height: 100vh;

		-webkit-animation: breathing 7s ease-out infinite normal;
    	animation: breathing 7s ease-out infinite normal;
	}

	@-webkit-keyframes breathing {
	0% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	25% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	60% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	}

	@keyframes breathing {
	0% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}

	25% {
		-webkit-transform: scale(1);
		-ms-transform: scale(1);
		transform: scale(1);
	}

	60% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}

	100% {
		-webkit-transform: scale(0.9);
		-ms-transform: scale(0.9);
		transform: scale(0.9);
	}
	}

	/*
	@media screen and (max-width: 856px) {
		h1 {
			font-size: 6em;
		}

		p {
			font-size: 1.5em;
		}
	}
	*/

</style>
